/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import get from "lodash/get";
// Styles
import GetStyles, { Container } from "./style";

//Material
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid, useMediaQuery } from "@mui/material";

// Tracking
import saveUserEvent from "monitor-user-data";

//Comps
import GoogleMapComponent from "../../components/GoogleMap";
import OutlinedButton from "../../components/Button";
import StandardDropdown from "../../components/StandardDropdown";
import Autocomplete from "components/Autocomplete";

// Utils
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import routePaths from "../../routes/routePaths";
import {
  setCurrentSolution,
  setGlobalOptimalSolution,
  setOverallFilters,
} from "store/slices/filters_slice";
import { deserializeFilter } from "utils/deserializers/filters_deserializer";
import { setGraphData } from "store/slices/graph_slice";
import { deserializeGraph } from "utils/deserializers/graph_deserializer";

// actions
import { goToRoute } from "../../store/actions/navigationActions";
import { setIsSiteSelected } from "../../store/slices/flags_slice";
import {
  fetchApplications,
  fetchSiteCordinates,
  fetchSiteInfo,
  fetchSitePM25,
  fetchSites,
  getGlobalOptimalData,
  getGuestTokenDetails,
} from "api";
import { fetchLatLngFromSite, getCountry, isLabel } from "./utils";
import {
  setCoordinates,
  setDataOnOptimizeClick,
  setSiteInfo,
} from "store/slices/global_data_slice";

//assets
import logobg from "../../assets/logobg.svg";
import { StyledTextField } from "components/Autocomplete/style";
import { useTheme } from "@emotion/react";
import {
  showNotification,
  showToasterMessage,
} from "store/slices/modals_slice";
import { getAuthTokenValue, getCookie } from "utils/auth-token-utils";
import { setUserDetails } from "store/slices/user_slice";
import { duration } from "moment";

function Dashboard(props) {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const dispatch = useDispatch();
  const theme = useTheme();
  const styles = GetStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const ref = useRef(true);

  // Maps State
  const [searchTerm, setSearchTerm] = useState("");
  const [searchSiteTerm, setSearchSiteTerm] = useState("");
  const [clickedPosition, setClickedPosition] = useState(null);
  const [placeDetails, setPlaceDetails] = useState(null);
  const [searchCoordinates, setSearchCoordinates] = useState(null);

  const [isFormValid, setIsFormValid] = useState(false);
  const [gasTurbine, setgasTurbine] = useState("");
  const [selectedApplication, setSelectedApplication] = useState("Select One");
  const [gasTurbineInvalid, setGasTurbineInvalid] = useState(false);

  // Dropdown Options
  const [siteOptions, setSiteOptions] = useState([]);
  const [applicationOptions, setApplicationOptions] = useState([]);
  useEffect(() => {
    if (getAuthTokenValue() && ref.current) {
      ref.current = false;
      const uuid = getCookie("UUID");
      if (uuid) {
        getGuestTokenDetails(uuid)
          .then((res) => dispatch(setUserDetails(res.data[0])))
          .catch((err) => {
            console.log(err);
          });
      }
      fetchSites()
        .then((res) => setSiteOptions(res.data))
        .catch((err) => {
          console.log(err);
        });
      fetchApplications()
        .then((res) => setApplicationOptions(["Select One", ...res.data]))
        .catch((err) => {
          console.log(err);
        });
    }
  }, [getAuthTokenValue()]);

  const handleInputChange = (value) => {
    const regex = /\d+/;
    if (value?.length === 0) {
      setgasTurbine(value);
      setGasTurbineInvalid(true);
      return;
    }
    if (value?.length > 3) {
      return;
    }
    if (!regex.test(value)) {
      return;
    }
    const intVal = parseInt(value);
    if (intVal < 5 || intVal > 600) {
      setgasTurbine(value);
      setGasTurbineInvalid(true);
      return;
    }
    setgasTurbine(value);
    setGasTurbineInvalid(false);
  };

  const isGasTurbineValid = (value) => {
    const intVal = parseInt(value);
    const regex = /\d+$/;
    if (value?.length === 0) {
      return false;
    }
    if (value?.length > 3) {
      return false;
    }
    if (!regex.test(value)) {
      return false;
    }
    if (intVal < 5 || intVal > 600) {
      setgasTurbine(value);
      setGasTurbineInvalid(true);
      return false;
    }
    return true;
  };

  const onChangeLocation = (e, value) => {
    console.log(value, e);
    setSearchTerm(value);
  };

  const checkSiteNameOrCoordinates = (e, value) => {
    setSearchSiteTerm(value);
  };

  const inputErrorNotif = () => {
    dispatch(
      showNotification({
        message:
          "Please input a valid address or coordinate values, or select from our site options.",
        severity: "error",
      })
    );
  };

  // Adds 'newSite' to the autocomplete site options if it's not already there
  const addToSiteOptions = (newSite) => {
    const baseName = newSite.split(" - ")[0]; // Get everything before the first ' - '

    setSiteOptions((prevOptions) => {
      if (!prevOptions.some((option) => option.startsWith(baseName))) {
        return [...prevOptions, newSite];
      }
      return prevOptions;
    });
  };

  // Capitalizes the first letter of every word from 'str'
  const formatString = (str) => {
    return str
      .split(" ")
      .map((word) => {
        if (!word) return "";
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  const handleSearch = async (cordinates = null, site = null) => {
    if (cordinates) {
      const siteResp = await fetchSiteInfo(cordinates);
      dispatch(setSiteInfo(siteResp.data[0]));
      const [lat, lng] =
        cordinates.split(",").length == 2
          ? cordinates.split(",")
          : cordinates.split(" ");
      setClickedPosition({
        lat: Number(lat),
        lng: Number(lng),
      });
      dispatch(setCoordinates({ lat: Number(lat), lng: Number(lng) }));
      fetchSitePM25(cordinates).then(async (res) => {
        const country = await getCountry({
          lat: parseFloat(parseFloat(lat).toFixed(4)),
          lng: parseFloat(parseFloat(lng).toFixed(4)),
        });
        setPlaceDetails({
          label: "Coordinates: " + cordinates,
          plots: get(res, "data.0", {}),
          country: country,
        });
      });
      setSearchCoordinates("");
      return;
    }
    if (site) {
      const res = await fetchLatLngFromSite(site);
      if (res) {
        // Converting the found address to coordinates
        const { lat, lng, placeName, city_long, country_long } = res;
        const coordinates = lat + "," + lng;
        setClickedPosition({
          lat,
          lng,
        });
        dispatch(setCoordinates({ lat: Number(lat), lng: Number(lng) }));
        fetchSitePM25(coordinates).then(async (res2) => {
          setPlaceDetails({
            label: placeName,
            plots: get(res2, "data.0", {}),
          });
        });
        const siteResp = await fetchSiteInfo(coordinates);
        dispatch(setSiteInfo(siteResp.data[0]));

        // Adding an autocomplete history from a successful search
        const autocomplete_term =
          formatString(site) + " - " + city_long + ", " + country_long;
        addToSiteOptions(autocomplete_term);

        // SearchCoordinates stores our site, so that we can pretty print with searchTerm
        setSearchCoordinates(coordinates);
      } else {
        inputErrorNotif();
      }
      setSearchSiteTerm("");
      return;
    }
    if (searchTerm) {
      var siteResp;
      try {
        siteResp = await fetchSiteInfo(searchTerm);
      } catch (error) {
        // Normal branch failure for google search input
        console.log("Normal error from searchTerm");
        return;
      }
      dispatch(setSiteInfo(siteResp.data[0]));
      fetchSiteCordinates(searchTerm)
        .then((res) => {
          // Only change placeDetails if a SITE has been searched
          // (res -> fetched coordinates from a site. Fetching coordinates from coordinates will skip the
          // placeDetails changes since they've already been covered in the 'if (coordinates)' branch)
          if (res.data.latitude[0] != undefined) {
            const { latitude, longitude } = res.data;
            setClickedPosition({
              lat: latitude[0],
              lng: longitude[0],
            });
            dispatch(
              setCoordinates({
                lat: Number(latitude[0]),
                lng: Number(longitude[0]),
              })
            );
            fetchSitePM25(searchTerm).then(async (res) => {
              const country = await getCountry({
                lat: latitude[0],
                lng: longitude[0],
              });
              setPlaceDetails({
                label: searchTerm,
                plots: get(res, "data.0", {}),
                country,
              });
              setSearchSiteTerm("");
              setSearchCoordinates("");
            });
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            showToasterMessage({
              message: "Failed to Fetch Site",
              toasterColor: "primary",
            })
          );
        });
    }
    // else {
    //   dispatch(
    //     showToasterMessage({
    //       message: "Failed to Fetch Site",
    //       toasterColor: "primary",
    //     })
    //   );
    // }
  };

  useEffect(() => {
    handleSearch();
  }, [searchTerm]);

  const handleDropdown = (e) => {
    setSelectedApplication(e.target.value);
  };

  const check_lat_lon = (lat, lon) => {
    const regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
    const regexLon =
      /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;
    let validLat = regexLat.test(lat);
    let validLon = regexLon.test(lon);
    return validLat && validLon;
  };

  const onClickSearch = () => {
    if (searchSiteTerm) {
      const regex = /^-?\d+(\.\d+)?,-?\d+(\.\d+)?$/;
      const splittedStr = searchSiteTerm.split(",");
      const splittedStr2 = searchSiteTerm.split(" ");
      if (searchSiteTerm.match(regex)) {
        handleSearch(searchSiteTerm);
      } else if (
        (splittedStr?.length == 2 &&
          check_lat_lon(splittedStr[0], splittedStr[1].split(" ")[1])) ||
        (splittedStr2?.length == 2 &&
          check_lat_lon(splittedStr2[0], splittedStr2[1]))
      ) {
        handleSearch(searchSiteTerm);
      } else {
        handleSearch(null, searchSiteTerm);
      }
    }
  };

  const onClickOptimize = () => {
    // Tracking the 'Optimize' button click
    const fullName = activeAccount?.idTokenClaims?.name;
    const email = activeAccount?.idTokenClaims?.email;
    const eventType = "Clicked 'Optimize' Button";
    const site = searchCoordinates
      ? searchCoordinates
      : searchTerm || searchSiteTerm;

    saveUserEvent(fullName, email, eventType, site);

    if (searchCoordinates) {
      setSearchTerm(searchCoordinates);
      setSearchSiteTerm("");
    }

    console.log("OPTIMIZING WITH SEARCHTERM VALUE: " + searchTerm);
    if (isFormValid) {
      const optimizeData = {
        site: searchCoordinates
          ? searchCoordinates
          : searchTerm || searchSiteTerm,
        application: selectedApplication,
        output_input: gasTurbine,
      };
      getGlobalOptimalData(
        optimizeData.site,
        optimizeData.application,
        optimizeData
      )
        .then((res) => {
          const initGraph = deserializeGraph(res.data?.detail);
          dispatch(setGraphData(deserializeGraph(res.data?.detail, initGraph)));
          dispatch(setGlobalOptimalSolution(res.data));
          dispatch(setCurrentSolution(res.data));
          dispatch(
            setOverallFilters(deserializeFilter(res.data.filter, {}, dispatch))
          );
        })
        .catch((err) => {
          dispatch(
            showToasterMessage({
              message: "Error to process your request.",
              toasterColor: "primary",
            })
          );
          dispatch(setDataOnOptimizeClick({}));
          dispatch(goToRoute(routePaths.DASHBOARD));
          dispatch(setIsSiteSelected(false));
        });
      dispatch(setDataOnOptimizeClick(optimizeData));
      dispatch(goToRoute(routePaths.HOME));
      dispatch(setIsSiteSelected(true));
    }
  };

  const checkFormValidity = () => {
    // Check if all required fields are filled
    const isValid =
      clickedPosition &&
      isGasTurbineValid(gasTurbine) &&
      selectedApplication !== "Select One";
    setIsFormValid(isValid);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onClickSearch();
    }
  };

  useEffect(() => {
    checkFormValidity();
  }, [clickedPosition, gasTurbine, selectedApplication]);
  return (
    <>
      <Box
        pl={2}
        pr={2}
        pb={2}
        sx={{ overflow: "hidden", position: "relative" }}
      >
        <img src={logobg} alt="camfil" style={styles.bg} />
        <Box sx={styles.mapContainer}>
          <GoogleMapComponent
            placeDetails={placeDetails}
            clickedPosition={clickedPosition}
          />
        </Box>
        <Container>
          <Typography style={{ ...styles.labelStyles, marginBottom: 0 }}>
            Welcome to FAST!
          </Typography>
          <Typography
            style={{
              color: theme.palette.mode === "dark" ? "#31FFCD" : "#008c64",
              fontFamily: "newsGothic-std",
              marginBottom: "2rem",
            }}
            sx={{
              fontSize: {
                xs: "20px",
                md: "25px",
                lg: "32px",
              },
            }}
          >
            Camfil’s Filtration Awareness Simulation Tool
          </Typography>
          <FormControl fullWidth>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6} sx={styles.gridItem}>
                <Box sx={styles.inputContainer}>
                  <Typography
                    sx={{
                      ...styles.label,
                      // color: theme.palette.mode == "dark" ? "#FFF" : "#000",
                    }}
                  >
                    Select Your Site:
                  </Typography>
                  <Autocomplete
                    options={siteOptions}
                    value={searchTerm}
                    name="siteName"
                    placeholder="Enter site name or coordinates in digits e.g. (45, -73) "
                    onChange={onChangeLocation}
                    onInputChange={checkSiteNameOrCoordinates}
                    onSearch={onClickSearch}
                    isKeyPressNeeded={true}
                    handleKeyDown={handleKeyDown}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} sx={styles.gridItem}>
                <Box sx={styles.inputContainer}>
                  <Typography sx={styles.label}>Choose Application:</Typography>
                  <StandardDropdown
                    customBackgroundColor="#a4cdbd"
                    option={applicationOptions.map((label) => ({
                      label,
                      value: label,
                    }))}
                    name="selectedApplication"
                    placeholder={"Select One"}
                    value={selectedApplication}
                    fullWidth
                    onChange={handleDropdown}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: isLabel(selectedApplication)
                        ? "grey"
                        : theme.palette.mode !== "dark"
                        ? "#000"
                        : "#fff",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} mt="1rem" sx={styles.gridItem}>
                <Box
                  sx={{
                    ...styles.inputContainer,
                    ...(gasTurbineInvalid ? { alignItems: "center" } : {}),
                  }}
                >
                  <Typography sx={styles.label}>
                    Gas Turbine Output (MW)
                  </Typography>
                  <StyledTextField
                    variant={"standard"}
                    placeholder="5-600"
                    value={gasTurbine}
                    onChange={(e) => handleInputChange(e.target.value)}
                    fullWidth
                    sx={{ borderBottom: gasTurbineInvalid && "none" }}
                    error={gasTurbineInvalid}
                    helperText={
                      !gasTurbineInvalid
                        ? ""
                        : "Please enter valid value between 5 to 600"
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} mt="1rem" sx={styles.gridItem}>
                <Box
                  sx={{
                    ...(isMobile
                      ? { minHeight: "55px" }
                      : { position: "relative", bottom: "8px" }),
                  }}
                >
                  <OutlinedButton
                    variant="outlined"
                    customcolor={
                      theme.palette.mode === "dark" ? "#080809" : "#ffffff"
                    }
                    label="Optimize"
                    onClick={onClickOptimize}
                    customBackgroundColor={
                      theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD"
                    }
                    customBorderColor={
                      theme.palette.mode !== "dark" ? "#008c64" : "#31FFCD"
                    }
                    disabled={!isFormValid}
                    width="100%"
                    sx={{ borderRadius: "4px", position: "absolute" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </FormControl>
        </Container>
      </Box>
    </>
  );
}

Dashboard.propTypes = {};

export default Dashboard;
