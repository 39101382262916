import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

// Mui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// Tracking
import saveUserEvent from "monitor-user-data";

// Assets
import camfil_logo from "../../assets/camfil_logo.svg";
import camfil_green_logo from "../../assets/camfil_green_logo.svg";
import camfil_new_light from "../../assets/camfil_new_light_logo.svg";
import camfil_new_dark from "../../assets/camfil_new_dark_logo.svg";

// Utils
import { getMenuBarRoutes, isSelectedRoute } from "./utils";
import { ReactComponent as NightIcon } from "../../assets/night-icon.svg";
import { ReactComponent as DayIcon } from "../../assets/day-icon.svg";
// actions
import { goToRoute } from "../../store/actions/navigationActions";
import {
  setInviteModal,
  showNotification,
  showToasterMessage,
} from "store/slices/modals_slice";
import { useMediaQuery, useTheme } from "@mui/material";
import routePaths from "routes/routePaths";
import { generatePDFReport } from "api";
import { useState } from "react";
import { setIsDarkMode, setIsSiteSelected } from "store/slices/flags_slice";
import useLocalStorage from "utils";
import MaterialUISwitch from "components/Switch/style";
import { useMsal } from "@azure/msal-react";
function MenuBar() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useSelector((state) => state.router.location);
  const { isSiteSelected } = useSelector((state) => state.flags);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const routes = getMenuBarRoutes(location, isSiteSelected);
  const { site } = useSelector((state) => state.globalData);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useLocalStorage("isDarkMode", true);
  const currentTime = new Date();
  const expirationDate = localStorage.getItem("expirationDate")
    ? JSON.parse(localStorage.getItem("expirationDate"))
    : null;
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [isApproved, setIsApproved] = useState(false);

  React.useEffect(() => {
    let flag = true;
    if (
      activeAccount.idTokenClaims?.tid != "ba9d205c-25f9-4b1f-9faa-519bd940802b"
    ) {
      flag = false;
      if (expirationDate) {
        if (typeof expirationDate == "string") {
          const expDate = new Date(expirationDate);
          flag = expDate > currentTime;
        } else {
          flag = expirationDate > currentTime;
        }
      }
    }
    setIsApproved(flag);
  }, [activeAccount.idTokenClaims?.tid, expirationDate, currentTime]);

  const onClick = (route) => {
    if (route === routePaths.SITE_REPORT) {
      // TRACK SITE REPORT
      const fullName = activeAccount?.idTokenClaims?.name;
      const email = activeAccount?.idTokenClaims?.email;
      const eventType = "Clicked 'Generate Site Report' Button";
      saveUserEvent(fullName, email, eventType, site);

      if (!loading) {
        generateReport();
        dispatch(goToRoute(route));
      }
    } else {
      if (route) dispatch(goToRoute(route));
      else dispatch(setInviteModal(true));
    }
  };

  const generateReport = () => {
    setLoading(true);
    generatePDFReport(site, activeAccount?.name)
      .then(async (response) => {
        const pdfString = await response.data;
        const pdfBlob = new Blob([pdfString], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
        dispatch(goToRoute(routePaths.CONTACT_SALES));
        setLoading(false);
      })
      .catch((err) => {
        dispatch(
          showNotification({
            message:
              "Oops! Something went wrong, and the report could not be generated. Please contact your sales representative for assistance.",
            severity: "error",
          })
        );
        setLoading(false);
      });
  };

  const redirectToDashboard = () => {
    window.location.href = routePaths.DASHBOARD; // Navigate to the dashboard
  };

  const handleSwitchChange = (ev) => {
    setState(JSON.stringify(ev.target.checked));
    dispatch(setIsDarkMode(ev.target.checked));
  };
  return (
    <nav
      style={
        theme.palette.mode == "light"
          ? { borderBottom: "1px solid #BBC3D8" }
          : {}
      }
    >
      <Box
        sx={{
          backgroundImage:
            "linear-gradient(90.26deg, rgba(219, 245, 253, 0.18) -0.4%, rgba(219, 245, 253, 0.16) 22.85%, rgba(219, 245, 253, 0.1) 48.22%, rgba(219, 245, 253, 0.16) 77.29%, rgba(219, 245, 253, 0.18) 101.07%)",
          display: "flex",
          justifyContent: "space-between",
          height: "70px",
          alignItems: "center",
          paddingX: "40px",
        }}
      >
        <Box
          onClick={() => {
            if (isApproved) {
              redirectToDashboard();
            }
          }}
        >
          <img
            src={
              theme.palette.mode == "dark" ? camfil_new_dark : camfil_new_light
            }
            alt="camfil"
          />
        </Box>
        <Box>
          {isApproved &&
            routes &&
            routes.map((navItem, index) => (
              <Button
                key={navItem?.path}
                variant="text"
                onClick={() => navItem.clickEnabled && onClick(navItem.path)}
                sx={{
                  mx: "4px",
                  fontSize: isMobile ? "11px" : "14px",
                  textTransform: "none",
                  color: (theme) =>
                    isSelectedRoute(navItem.path, location)
                      ? theme.palette.mode !== "dark"
                        ? "#008c64"
                        : theme.palette.primary.main
                      : theme.palette.mode === "dark"
                      ? "#fff"
                      : "#000",
                }}
              >
                {navItem.label}
              </Button>
            ))}
          <MaterialUISwitch
            onChange={handleSwitchChange}
            checked={state}
            checkedIcon={
              <NightIcon
                width={40}
                style={{ position: "relative", bottom: "12px" }}
              />
            }
            icon={
              <DayIcon
                width={40}
                style={{ position: "relative", bottom: "12px", left: "-11px" }}
              />
            }
          />
        </Box>
      </Box>
    </nav>
  );
}

export default MenuBar;
