// monitor-user-data.js
import axios from "axios";

const saveUserEvent = async (full_name, email, action_type, site) => {
  const montrealDate = new Date().toLocaleString("en-CA", {
    timeZone: "America/Toronto",
    hour12: false,
  });

  // Convert the formatted string back into a valid ISO timestamp
  const [date, time] = montrealDate.split(", ");
  const timestamp = `${date}T${time}.0000000`;
  const platform = "FAST";

  const data = { full_name, email, action_type, timestamp, platform, site };

  try {
    const response = await axios.post(
      "https://fastusertrackbackend-ekdfeeg9gqfgdmhw.canadacentral-01.azurewebsites.net/track-login",
      data,
      { headers: { "Content-Type": "application/json" } }
    );

    console.log("Response:", response.data);
  } catch (error) {
    console.error("Error saving user data:", error);
  }
};

export default saveUserEvent; // This is how you export it
